<template>
  <div class="campaign">
    <div class="top-bar">
      <div class="platform">
        <van-dropdown-menu>
          <van-dropdown-item v-model="currentPlatform" :options="platform" @change="selectPlat" />
        </van-dropdown-menu>
      </div>
      <div class="search">
        <van-search v-model="keyword" placeholder="请输入账户或者系列名称" @search="onSearch" @blur="trim('keyword')" />
      </div>
    </div>
    <div class="campaign-list">
      <list-bar :params="params" @setParams="setParams"></list-bar>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" :disabled="disabledRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getList"
          v-if="sortList.length"
        >
          <campaign-card
            v-for="(item, index) in sortList"
            :key="index"
            :data="item"
            :initData="initData"
            :listParams="listParams"
            :params="params"
            :index="index"
            @changeStatus="changeStatus"
            @setData="setData"
            @emitOpenSchedule="emitOpenSchedule"
            :dateRange="dateRange"
            @changeDisabledRefresh="changeDisabledRefresh"
          ></campaign-card>
        </van-list>
        <van-empty description="暂无数据" v-else />
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import listBar from '@/views/components/listBar.vue';
import campaignCard from '@/views/campaign/campaignCard.vue';
// import { campaignList,adConstsMap } from "@/api/campaign";
import globalData from '@/minixs/data.js';
import editAdMix from '@/views/components/editAdMix.js';
// import { Empty } from 'vant';
export default {
  components: {
    listBar,
    campaignCard,
  },
  mixins: [globalData, editAdMix],
  data() {
    return {
      // currentPlatform: localStorage.getItem("platform") || "facebook",
      // keyword: "",
      params: {
        currency: null,
        time: 1,
        syncData: false,
        sort: 'origin_spend#DESC',
      },
      disabledRefresh: false, // 禁止下来刷新
      // listParams: {},
      // list: [],
      // currentPage: 0,
      // pageSize: 10,
      // finished: false,
      // loading: false,
      // refreshing:false
    };
  },
  computed: {
    sortList: {
      get() {
        return this.list || [];
      },
      set() {},
    },
  },
  methods: {
    getList() {
      if (!this.params.time && !this.dateRange) {
        return;
      }
      let params = {
        platform: this.currentPlatform,
        adEntityName: this.keyword,
        startDate: this.params.time
          ? this.formatDate(this.dateList.filter((v) => v.value == this.params.time)[0].start(), { tag: 1 })
          : this.formatDate(this.dateRange[0], { tag: 1 }),
        endDate: this.params.time
          ? this.formatDate(this.dateList.filter((v) => v.value == this.params.time)[0].end(), { tag: 1 })
          : this.formatDate(this.dateRange[1], { tag: 1 }),
        // startDate:null,
        // endDate:null,
        currency: this.params.currency,
        sort: 'SPEND',
        level: 'campaign',
        orderBy: this.params.sort.split('#')[0],
        ascOrDesc: this.params.sort.split('#')[1],
        gkPage: {
          //分页信息。可以为空。空时使用默认值。
          index: this.currentPage,
          size: this.pageSize,
        },
      };
      this.getDefaultList(params);
    },
    changeStatus(params, idKey, id, key, val) {
      this.editStatus(params, idKey, id, key, val);
    },
    onSearch() {
      this.currentPage = 0;
      this.list = [];
      this.getList();
    },
    // 去空格
    trim(key) {
      let newValue = this[key].trim();
      this.$set(this, key, newValue);
    },
    changeDisabledRefresh(bool) {
      this.disabledRefresh = bool;
    },
  },
};
</script>
<style lang="less">
.campaign {
  .van-dropdown-menu__title--active,
  .van-dropdown-item__option--active .van-dropdown-item__icon,
  .van-dropdown-item__option--active {
    color: #1989fa;
  }
  .top-bar {
    display: flex;
    width: 100%;
    .platform {
      flex: 240px 0 0;
      .van-dropdown-menu__bar {
        box-shadow: none;
      }
    }
    .search {
      flex: 1;
    }
  }
  .campaign-list {
    padding: 0 40px;
    border-top: 1px solid #ebedf0;
  }
}
</style>
