<!-- 经营分析 -->

<template>
  <van-popup position="bottom" v-model="showPopup" closeable close-on-popstate @close="close">
    <div class="productReport">
      <div class="title">经营分析详情</div>
      <van-row class="mt-10">
        <van-col span="6">
          <div class="subTitle">连续警告</div>
          <div>{{ data.lossDays || '--' }}天</div>
        </van-col>
        <van-col span="6">
          <div class="subTitle">经营评估</div>
          <div>
            <van-image width="20" height="20" :src="iconSrc[data.profitRateResult]" v-if="data.profitRateResult" />
            <span v-else>--</span>
          </div>
        </van-col>
        <van-col span="6">
          <div class="subTitle">签收率</div>
          <div>{{ data.signRate }}</div>
        </van-col>
        <van-col span="6">
          <div class="subTitle">毛利率</div>
          <div>
            {{ data.grossMarginRate ? data.grossMarginRate + '%' : '--' }}
          </div>
        </van-col>
      </van-row>
      <van-row class="mt-10">
        <van-col span="6">
          <div class="subTitle">ROI</div>
          <div>{{ data.roi }}</div>
        </van-col>
        <van-col span="6">
          <div class="subTitle">ROI临界值</div>
          <div>
            {{ data.lowestRoi ? data.lowestRoi + '%' : '--' }}
          </div>
        </van-col>
        <van-col span="6">
          <div class="subTitle">平均广告费</div>
          <div>{{ data.avgAdCost }}</div>
        </van-col>
        <van-col span="6">
          <div class="subTitle">广告临界值</div>
          <div>
            {{ data.adCriticalVal ? data.adCriticalVal + '%' : '--' }}
          </div>
        </van-col>
      </van-row>
      <van-row class="mt-10">
        <van-col span="6">
          <div class="subTitle">订单量</div>
          <div>{{ data.orderCount }}</div>
        </van-col>
        <van-col span="6">
          <div class="subTitle">客单量</div>
          <div>{{ data.countPerCustomer }}</div>
        </van-col>
        <van-col span="6">
          <div class="subTitle">客单价</div>
          <div>{{ data.amountPerCustomer }}</div>
        </van-col>
        <van-col span="6">
          <div class="subTitle">广告费占比</div>
          <div>
            {{ data.adRate ? data.adRate + '%' : '--' }}
          </div>
        </van-col>
      </van-row>
      <van-row class="mt-10">
        <van-col span="6">
          <div class="subTitle">采购占比</div>
          <div>
            {{ data.buyRate ? data.buyRate + '%' : '--' }}
          </div>
        </van-col>
        <van-col span="6">
          <div class="subTitle">运费占比</div>
          <div>
            {{ data.logisticsRate ? data.logisticsRate + '%' : '--' }}
          </div>
        </van-col>
        <van-col span="6">
          <div class="subTitle">三项成本合计</div>
          <div>{{ data.threeRate ? data.threeRate + '%' : '--' }}</div>
        </van-col>
        <van-col span="6">
          <div class="subTitle">其他占比</div>
          <div>{{ data.otherRate ? data.otherRate + '%' : '--' }}</div>
        </van-col>
      </van-row>
    </div>
  </van-popup>
</template>

<script>
import globalData from '@/minixs/data';

export default {
  props: {
    show: Boolean,
    data: Object,
  },
  mixins: [globalData],
  data() {
    return {
      showPopup: false,
    };
  },
  methods: {
    close() {
      this.$emit('update:show', false);
    },
  },
  watch: {
    show: {
      immediate: true,
      handler: function (val) {
        this.showPopup = val;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.productReport {
  padding: 0 24px 100px;
  color: #333;
  line-height: 44px;
  font-size: 30px;
  .title {
    height: 120px;
    line-height: 120px;
    font-size: 36px;
    text-align: center;
  }
  .mt-10 {
    margin-top: 30px;
  }
  .subTitle {
    color: #999;
  }
}
</style>
