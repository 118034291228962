<template>
  <div class="campaign-card">
    <div class="card-title">
      <p @click="editName">
        <span v-show="!showInput">{{ data.ad.campaignName }}</span>
        <van-field class="editNameInput" v-show="showInput" v-model="newName" placeholder="请输入用户名">
          <template #button>
            <van-button type="primary" class="mr5" size="mini" @click.stop="changeName" :loading="loading"
              >确认</van-button
            >
            <van-button type="default" size="mini" @click.stop="setShowInput(false)">取消</van-button>
          </template>
        </van-field>
        <!-- <div v-show="showInput">
          <van-button type="primary" class="mr5" size="mini">确认</van-button>
          <van-button type="primary" size="mini">取消</van-button>
        </div> -->
      </p>
      <p class="account">
        <span class="platform">{{ data.ad.platform }}</span>
        <span class="name"> {{ data.ad.adAccountName }} </span>
        <span :class="['date', syncDate.class]"> {{ syncDate.time }} </span>
      </p>
    </div>
    <div class="data-msg">
      <div class="campaignData" @click="goAdset">
        <div class="data-item">
          <p class="desc">花费</p>
          <span> {{ data.statistics.currency }}{{ data.statistics.spend.toFixed(2) }} </span>
        </div>
        <div class="data-item">
          <p class="desc">订单/成本</p>
          <span> {{ data.statistics.orderCount }}/{{ data.statistics.perOrderCost.toFixed(2) }} </span>
        </div>
        <div class="data-item">
          <p class="desc">添加支付/成本</p>
          <span> {{ data.statistics.addPayCount }}/{{ data.statistics.perAdPayCost.toFixed(2) }} </span>
        </div>
        <div class="data-item" v-if="data.ad.platform == 'line'">
          <p class="desc">dopay/成本</p>
          <span>
            {{ data.statistics.dopay }}/{{ data.statistics.finalCost ? data.statistics.finalCost.toFixed(2) : '--' }}
          </span>
        </div>
        <div class="data-item">
          <p class="desc">毛利率</p>
          <span>{{
            data.statistics.productReport && data.statistics.productReport.profitRatePre
              ? data.statistics.productReport.profitRatePre.toFixed(2) + '%'
              : '--'
          }}</span>
        </div>
        <div class="data-item">
          <p class="desc">{{ data.ad.platform == 'twitter' ? '链接' : '' }}点击数</p>
          <span>{{ data.statistics.click || 0 }}</span>
        </div>
        <div class="data-item">
          <p class="desc">{{ data.ad.platform == 'twitter' ? '链接' : '' }}点击成本</p>
          <span>{{ data.statistics.perClickCost || '--' }}</span>
        </div>
        <div class="data-item" v-if="data.ad.platform == 'twitter'">
          <p class="desc">点赞数</p>
          <span>{{ data.statistics.like || 0 }}</span>
        </div>
        <div class="data-item" @click.stop="goReport(data.statistics.productReport)" id="productReport">
          <p class="desc">经营评估</p>
          <van-image
            width="20"
            height="20"
            :src="iconSrc[data.statistics.productReport.profitRateResult]"
            v-if="data.statistics.productReport && data.statistics.productReport.profitRateResult"
          />
          <span v-else>--</span>
        </div>
        <div
          class="dp-flex flex-d-c AIRecommendation"
          :class="{
            cF88: aiSuggestion.type === 'PAUSE',
            cFB6: aiSuggestion.type === 'SUB_BUDGET',
            c80D580: aiSuggestion.type === 'ADD_BUDGET',
            ccddff: aiSuggestion.type === 'NO_OP',
            overTime: isOverTime(aiSuggestion.suggestionTime),
          }"
          v-if="data.ad.aiSuggestion"
        >
          <div class="dp-flex align-item-c mb-10">
            <div
              class="iconfont mr-10"
              :class="{
                'icon-guanting': data.ad.aiSuggestion.type === 'PAUSE',
                'icon-zengjiayusuan-copy': data.ad.aiSuggestion.type === 'SUB_BUDGET',
                'icon-zengjiayusuan': data.ad.aiSuggestion.type === 'ADD_BUDGET',
                'icon-xianzhuang': data.ad.aiSuggestion.type === 'NO_OP',
              }"
            ></div>
            <div class="mr-10">
              {{ data.ad.aiSuggestion.message }}
            </div>
          </div>

          <div class="timeGrey" :class="{ overTime: isOverTime(aiSuggestion.suggestionTime) }">
            <van-icon name="clock-o" class="mr-10" />{{ data.ad.aiSuggestion.suggestionTime.split(' ')[1].slice(0, 5) }}
          </div>
        </div>
      </div>
      <div class="arrow"><van-icon name="arrow" /></div>
    </div>
    <div class="tool-budget">
      <div class="tool-item" @click="editBudget">
        <p v-if="data.ad.campaignDailyBudget || data.ad.campaignLifetimeBudget">
          <span> {{ data.ad.campaignDailyBudget ? '日' : '总' }}预算({{ data.statistics.currency }})： </span>
          <span class="money">
            {{ data.ad.campaignDailyBudget || data.ad.campaignLifetimeBudget }}
            <van-icon name="edit" />
          </span>
        </p>
        <p v-else>预算：--</p>
      </div>
      <div class="tool-item" v-if="data.ad.campaignTargetCpa" @click.stop="editTargetCpa">
        <p>
          <span>目标费用：</span>
          <span class="money"
            >{{ data.ad.campaignTargetCpa }}
            <van-icon name="edit" />
          </span>
        </p>
      </div>
    </div>
    <div class="tools">
      <div class="tool-item status">
        <span>状态：</span>
        <van-switch
          :value="data.ad.adCampaignStatus"
          :disabled="data.ad.adGroupStatus == 'DELETED' || data.ad.adGroupStatus == 'DELETED'"
          @input="onInput"
          active-value="ACTIVE"
          inactive-value="PAUSED"
          size="16px"
          id="changeStatus"
        />
      </div>
      <div class="tool-buttons">
        <div class="tool-item" v-if="data.ad.adCampaignStatus == 'PAUSED'">
          <van-button text="定时开启" type="info" round size="mini" @click="openClockShow = true" />
        </div>

        <div
          class="tool-item"
          v-if="data.ad.adCampaignStatus == 'ACTIVE' && (data.ad.campaignDailyBudget || data.ad.campaignLifetimeBudget)"
        >
          <van-button text="定时改预算" type="info" round size="mini" @click="f_showDialogBudgetClock" />
        </div>
        <div class="tool-item" v-if="data.ad.platform == 'twitter'">
          <van-button text="改出价" type="info" round size="mini" @click="showSetBidAmount" />
        </div>
        <div class="tool-item tool-button" @click.stop="handleOpenOperateDraw">
          <van-button type="info" size="mini" :loading="operateLoading" loading-type="spinner">操作日志</van-button>
        </div>
        <!--  v-if="data.ad && data.ad.platform === 'snapchat'" -->
        <div class="tool-item" @click.stop="copy">
          <van-button type="info" size="mini" class-prefix="my-button">复制</van-button>
        </div>
      </div>
    </div>
    <div class="tool-item">
      <div v-if="data.ad.open_schedule && data.ad.open_schedule.length > 0" :key="tableKey">
        <p v-for="(item, k) in data.ad.open_schedule" :key="data.id + k" class="open-schedule">
          <span v-if="item && item.type == 1" class="set-open">
            定时开启任务:{{ item.timeStr }}
            <i class="el-button" @click="cancelSet('task', data, index, k)"> 取消 </i>
          </span>
          <span v-if="item && item.type == 2" class="budget-span">
            定时修改预算:{{ item.timeStr }}
            <i class="el-button" @click="cancelSet('budget', data, index, k)"> 取消 </i>
          </span>
          <span v-if="item && item.type == 2" class="budget-span"> 预算金额：{{ item.budget }}</span>
          <span v-if="item && item.type == 3" class="set-open">
            定时修改出价:{{ item.timeStr }}
            {{ getIncreaseBidAmount(item.increaseBidAmount) }}
            <i class="el-button" @click="delUpdateBidTask(item.id, data, index, k)"> 取消 </i>
          </span>
        </p>
      </div>
    </div>
    <edit-budget
      :editBudgetShow.sync="editBudgetShow"
      :data="data"
      :type="type"
      level="campaign"
      @setListItem="setListItem"
    ></edit-budget>
    <!-- 定时开启 -->
    <open-clock
      :openClockShow.sync="openClockShow"
      :data="data"
      :initData="initData"
      :type="type"
      v-on="$listeners"
      @settingComplete="settingComplete"
      level="campaign"
    ></open-clock>
    <!-- 定时开启预算 -->
    <open-clock-budget
      :openClockBudgetShow.sync="openClockBudgetShow"
      :data="data"
      :initData="initData"
      ref="campaignBudget"
      level="campaign"
      @settingComplete="settingComplete"
      :type="type"
    >
    </open-clock-budget>
    <set-bid-amount
      :isShow.sync="isShowSetBidAmount"
      :data="data"
      :initData="initData"
      level="campaign"
      @set-time-success="setTimeSuccess"
    ></set-bid-amount>
    <!-- 复制 -->
    <van-popup v-model="showCopy" position="bottom" :closeable="true">
      <div class="copy-wrap">
        <h3 class="wrap-title">复制系列</h3>
        <van-field v-model="copyNum" label="复制份数：" placeholder="请输入复制份数" type="digit" />
        <van-field v-model="campaignDailyBudget" label="日预算：" placeholder="请输入广告系列日预算" type="digit" />
        <van-field
          v-if="data.ad.platform == 'snapchat'"
          v-model="adsetDailyBudget"
          label="组日预算："
          placeholder="请输入广告组日预算"
          type="digit"
        />
        <div class="btn-wrap">
          <van-button type="default" size="small" round @click="showCopy = false"> 取消 </van-button>
          <van-button type="info" size="small" round @click="copyCampaign"> 确定 </van-button>
        </div>
      </div>
    </van-popup>
    <operateLogPop
      ref="operateLogPop"
      :logFinish="logFinish"
      :logLoading="logLoading"
      v-model="logLoading"
      :operateContent="operateContent"
      @load="load"
    ></operateLogPop>
    <product-report :show.sync="showProductReport" :data="productReportMes"></product-report>
  </div>
</template>
<script>
import openClock from '@/views/components/openClock.vue';
import openClockBudget from '@/views/components/openClockBudget.vue';
import setBidAmount from '@/views/components/setBidAmount.vue';
import editBudget from '@/views/components/editBudget.vue';
import globalData from '@/minixs/data';
import editOpenClock from '../components/editOpenClock';
import operateLogPop from '@/views/components/operateLogPop.vue';
import productReport from '@/views/campaign/components/productReport';
import { Notify } from 'vant';
import { copyAdAsync, mutateName } from '@/api/campaign.js';
import { operateMethod } from '../adset/operateLog.js';
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    listParams: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
    dateRange: {
      type: Array,
      default: () => [],
    },
    initData: {
      type: Object,
      default: () => ({}),
    },
    level: {
      type: String,
      default: 'campaign',
    },
  },
  mixins: [globalData, editOpenClock, operateMethod],
  components: {
    editBudget,
    openClock,
    openClockBudget,
    setBidAmount,
    operateLogPop,
    productReport,
  },
  data() {
    return {
      editBudgetShow: false,
      tableKey: null,
      openClockShow: false,
      openClockBudgetShow: false,
      type: 'budget',
      isShowSetBidAmount: false,
      showCopy: false,
      copyNum: 1,
      campaignDailyBudget: 0,
      adsetDailyBudget: 0,
      showInput: false,
      newName: '',
      loading: false,
      // level: 'campaign',
      aiSuggestion: {
        id: 922108,
        dataTime: '2023-07-20 15:40:31',
        message: '减预算',
        objId: '23857575733840591',
        objType: 'campaign',
        suggestionTime: '2023-07-31 14:28:04',
        type: 'NO_OP',
      },
      showProductReport: false, // 经营分析显示
      productReportMes: {}, // 经营分析数据
    };
  },
  computed: {
    syncDate() {
      let date = this.data.ad.latestUpdateTime;
      //判断是不是今天
      let obj = {
        time: '--',
        class: 'default',
      };
      if (!date) return obj;
      // 判断是不是今天
      if (this.isToday(date)) {
        obj.time = date.slice(11, 16);
      } else {
        obj.time = date.slice(5, 10);
      }
      // 判断要显示的颜色
      if (this.isTreeToDay(date)) {
        obj.class = 'warn';
      }
      if (this.inThreeHours(date)) {
        obj.class = 'default';
      }
      if (this.beforeOneDay(date)) {
        obj.class = 'danger';
      }
      return obj;
    },
  },
  methods: {
    isOverTime(givenDateTime) {
      console.log(givenDateTime, 'givenDateTime');
      let now = new Date();
      console.log(now);

      let diffInMinutes = (new Date(givenDateTime) - now) / (1000 * 60);
      console.log(diffInMinutes, 'diffInMinutes');
      let isOver30Minutes = Math.abs(diffInMinutes) > 30;
      console.log(isOver30Minutes, 'isOver30Minutes');
      return isOver30Minutes;
    },
    getIncreaseBidAmount(amount) {
      return (amount > 0 ? '增加 ' : '减少 ') + Math.abs(amount);
    },
    //修改状态
    onInput(v) {
      if (this.data.ad.adGroupStatus == 'DELETED' || this.data.ad.adGroupStatus == 'DELETED') {
        Notify({ type: 'danger', message: '关闭状态不支持修改哦~~' });
        return false;
      }
      console.log(this.data);
      let params = {
        platform: this.data.ad.platform,
        level: 'campaign',
        accountId: this.data.ad.adAccountId,
        campaignId: this.data.ad.campaignId,
        credentialId: this.data.ad.credentialId,
        status: v,
      };
      this.$emit('changeStatus', params, this.data.ad.campaignId, 'campaignId', 'adCampaignStatus', v);
    },
    goAdset() {
      this.$router.push({
        name: 'adset',
        query: {
          campaignMsg: JSON.stringify(this.data),
          currency: this.params.currency,
          time: this.params.time,
          dateRange: JSON.stringify(this.dateRange),
        },
        params: {},
      });
    },
    editBudget() {
      if (this.data.ad.adCampaignStatus == 'DELETED' || this.data.ad.adCampaignStatus == 'DELETED') {
        Notify({ type: 'danger', message: '关闭状态不支持修改哦~~' });
        return false;
      }
      this.type = 'budget';
      this.editBudgetShow = true;
    },
    // 修改预算
    setListItem(key, val) {
      this.$emit('setData', this.data.ad.campaignId, 'campaignId', key, val);
    },
    // 改目标出价
    editTargetCpa() {
      if (this.data.ad.adCampaignStatus == 'DELETED' || this.data.ad.adCampaignStatus == 'DELETED') {
        Notify({ type: 'danger', message: '关闭状态不支持修改哦~~' });
        return false;
      }
      this.type = 'campaignTargetCpa';
      this.editBudgetShow = true;
    },
    // 跳转经营分析
    goReport(val) {
      if (val && val.profitRateResult) {
        this.showProductReport = true;
        this.productReportMes = val;
        // this.$router.push({
        //   name: 'productReport',
        //   query: { val: JSON.stringify(val) },
        // });
      }
    },
    // 判断是不是今天
    isToday(date) {
      let newDay = new Date(date.replace(/-/g, '/')).setHours(0, 0, 0, 0);
      let currentDate = new Date().setHours(0, 0, 0, 0);
      return newDay === currentDate;
    },
    // 是否是小于24小时且大于三小时
    isTreeToDay(date) {
      return Date.now() - new Date(date) < 24 * 60 * 60 * 1000 && Date.now() - new Date(date) > 3 * 60 * 60 * 1000;
    },
    // 是否是三小时内
    inThreeHours(date) {
      return Date.now() - new Date(date) < 3 * 60 * 60 * 1000;
    },
    // 是否是前一天
    beforeOneDay(date) {
      return Date.now() - new Date(date) > 24 * 60 * 60 * 1000;
    },
    copy() {
      this.campaignDailyBudget = this.data?.ad?.campaignDailyBudget ?? null;
      this.adsetDailyBudget = this.data?.ad?.adsetDailyBudget ?? null;
      this.showCopy = true;
    },
    // 复制系列
    copyCampaign() {
      const {
        data: { ad },
        campaignDailyBudget,
        adsetDailyBudget,
      } = this;
      if (this.data.ad.platform == 'snapchat' && !!String(campaignDailyBudget) && campaignDailyBudget < 20) {
        Notify({ type: 'warning', message: '系列日预算最少20' });
        return;
      }
      if (this.data.ad.platform == 'snapchat' && !!String(adsetDailyBudget) && adsetDailyBudget < 5) {
        Notify({ type: 'warning', message: '组日预算最少5' });
        return;
      }
      const data = {
        fromAccountId: ad.adAccountId,
        toAccountId: ad.adAccountId,
        platform: ad.platform,
        level: 'campaign',
        fromCampaign: ad.campaignId,
        campaignActive: ad.adCampaignStatus === 'ACTIVE',
        copyNum: this.copyNum,
        campaignDailyBudget,
        adsetDailyBudget,
      };
      if (this.data.ad.platform == 'snapchat') {
        data.adsetDailyBudget = adsetDailyBudget;
      }
      this.$showLoading();
      copyAdAsync(data)
        .then(() => {
          this.$hideLoading();
          Notify({ type: 'success', message: '复制成功，请稍后查看结果' });
          this.showCopy = false;
        })
        .catch(() => {
          this.$hideLoading();
        });
    },
    // 编辑名字
    editName() {
      this.newName = this.data.ad.campaignName;
      this.showInput = true;
    },
    changeName() {
      this.loading = true;
      const {
        ad: { platform, campaignId, adAccountId },
      } = this.data;
      const newName = this.newName;
      let params = {
        platform: platform,
        campaignId: campaignId,
        accountId: adAccountId,
        name: newName,
        level: 'campaign',
      };
      mutateName(params)
        .then(() => {
          this.showInput = false;
          // this.$emit("getList");
          this.$set(this.data.ad, 'campaignName', newName);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setShowInput(bool) {
      console.log(bool);
      this.showInput = bool;
      console.log(this.showInput);
    },
  },
  watch: {
    openClockShow(val) {
      this.$emit('changeDisabledRefresh', val);
    },
  },
};
</script>
<style lang="scss" scoped>
@import url(../../../src/assets/iconfont/iconfont.css);
.campaign-card {
  border-bottom: 1px solid #ebedf0;
  padding: 10px 0;
  .data-msg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .arrow {
      flex: 30px 0 0;
    }
  }
  .date {
    &.default {
      color: #07c160;
    }
    &.danger {
      color: #ee0a24;
    }
    &.warn {
      color: #ff976a;
    }
  }
  .card-title {
    // display: flex;
    width: 100%;
    font-size: 30px;
    line-height: 1.5;
    .account {
      font-size: 26px;
      color: #999;
      display: flex;
      justify-content: space-between;
      .platform {
        color: #1989fa;
        padding-right: 20px;
      }
      .name {
        flex: 1;
        overflow: hidden;
        // white-space: ;
        word-break: break-all;
      }
      .date {
        flex: 100px 0 0;
        text-align: right;
      }
    }
  }
  .campaignData {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0;
    font-size: 26px;
    flex-wrap: wrap;
    flex: 1;
    overflow: hidden;
    .data-item {
      width: 25%;
    }
    .desc {
      margin-bottom: 20px;
    }
    span {
      color: #ff0000;
      line-height: 52px;
    }
  }
  .tool-budget {
    display: flex;
    padding-bottom: 10px;
    .tool-item {
      font-size: 26px;
      margin-right: 30px;
    }
  }
  .tools {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 26px;
    .status {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 200px;
    }
  }
  .tool-buttons {
    display: flex;
    .tool-item {
      margin-left: 30px;
      .my-button {
        border-radius: 20px;
        padding: 2px;
      }
    }
  }
  /deep/.van-swipe-cell {
    .van-swipe-cell__right {
      .van-button {
        margin-left: 10px;
        height: 100%;
        width: 55px;
      }
    }
  }
}
.open-schedule {
  text-align: left;
  margin: 20px 0 10px 0;
  font-size: 26px;
  span {
    display: block;
  }
  .set-open {
    color: #409eff;
    margin-bottom: 5px;
  }
  .el-button {
    color: #e44646;
    font-size: 28px;
    font-style: normal;
  }
  .budget-span {
    color: #409eff;
  }
}
.copy-wrap {
  // margin-top: 100px;
  padding: 20px;
  .wrap-title {
    font-size: 40px;
    line-height: 100px;
    text-align: center;
  }
  .btn-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin-top: 50px;
    padding-bottom: 0;
    .van-button {
      width: 150px;
    }
  }
}
.editNameInput {
  background: #efefef;
  border-radius: 5px;
}
.mr5 {
  margin-right: 5px;
}
.AIRecommendation {
  margin-top: 10px;
  font-size: 26px;

  .timeGrey {
    color: #aaaaaa;
  }
  .overTime {
    color: #bbbbbb;
  }
  img {
    width: 40px;
    height: 40px;
    margin-right: 4px;
  }
  // span {
  //   color: #ccddff;
  // }
}
.ccddff {
  color: #99bbff;
}
.c80D580 {
  color: #80d580;
}
.cFB6 {
  color: #ffbb66;
}
.cF88 {
  color: #ff8888;
}
.overTime {
  color: #bbbbbb;
}
</style>
