<template>
  <div>
    <van-action-sheet
      v-model="isShow"
      title="修改出价"
      style="height: 500px"
      @cancel="onCancel"
      @click-overlay="onCancel"
    >
      <div class="content">
        <van-form>
          <van-field name="radio" label="是否定时修改">
            <template #input>
              <van-radio-group v-model="isSetTime" direction="horizontal">
                <van-radio :name="1">定时修改</van-radio>
                <van-radio :name="0">立即生效</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <template v-if="isSetTime">
            <van-field
              readonly
              clickable
              name="calendar"
              :value="handleDate"
              label="预计开启日期"
              placeholder="点击选择日期"
              ref="handleDate"
              @click="showCalendar = true"
            />
            <van-calendar v-model="showCalendar" @confirm="onConfirm" />
            <van-field
              readonly
              clickable
              name="picker"
              ref="taskType"
              :value="taskType"
              label="开启时间点"
              placeholder="点击开启时间点"
              @click="showPicker = true"
            />
            <van-popup v-model="showPicker" position="bottom">
              <!-- <van-picker
                show-toolbar
                :columns="taskTypes"
                value-key="desc"
                @confirm="onTaskTypeConfirm"
                @cancel="showPicker = false"
              /> -->
              <van-datetime-picker
                v-model="taskType"
                type="time"
                title="选择时间"
                @confirm="onTaskTypeConfirm"
                @cancel="showPicker = false"
              />
            </van-popup>
          </template>
          <van-field
            readonly
            clickable
            name="picker"
            :value="increaseBidAmount"
            ref="increaseBidAmount"
            label="增加金额"
            placeholder="请选择修改金额"
            @click="showAmount = true"
          />
          <van-popup v-model="showAmount" position="bottom">
            <van-picker show-toolbar :columns="amountList" @confirm="onAmountConfirm" @cancel="showAmount = false" />
          </van-popup>
          <div style="margin: 16px">
            <van-button round block type="info" @click="submitClock" id="editBudget"> 提交 </van-button>
          </div>
        </van-form>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { settingAdScheduleUpdateBidTasks, mutateBatchBid } from '@/api/campaign.js';
import { Notify } from 'vant';
import { mapState } from 'vuex';
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    level: {
      type: String,
      default: 'campaign',
    },
    initData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isSetTime: 1,
      handleDate: '',
      showCalendar: false,
      taskType: '',
      increaseBidAmount: '',
      amountList: [0.06, 0.05, 0.04, 0.03, 0.02, 0.01, -0.01, -0.02, -0.03, -0.04, -0.05, -0.06],
      showPicker: false,
      showAmount: false,
    };
  },
  computed: {
    ...mapState('user', {
      userInfo: (state) => state.userInfo,
    }),
    // taskTypes() {
    //   return this.initData?.ad?.AdScheduleUpdateBidTaskType ?? [];
    // },
  },
  methods: {
    onCancel() {
      this.$emit('update:isShow', false);
    },
    onConfirm(date) {
      this.handleDate = `${date.getFullYear()}-${(date.getMonth() + 1 + '').padStart(2, '0')}-${(
        date.getDate() + ''
      ).padStart(2, '0')}`;
      this.showCalendar = false;
    },
    onTaskTypeConfirm() {
      // this.taskType = value;
      this.showPicker = false;
    },
    onAmountConfirm(value) {
      this.increaseBidAmount = value;
      this.showAmount = false;
    },
    submitClock() {
      if (this.isSetTime) {
        this.setTimeTasks();
      } else {
        this.mutateBatchBid();
      }
    },
    mutateBatchBid() {
      const { increaseBidAmount, data, level } = this;
      console.log(data);
      if (!increaseBidAmount) {
        Notify({ type: 'warning', message: '请选择要修改的金额！' });
        this.showAmount = true;
        return;
      }
      const params = {
        increaseBidAmount,
        list: [
          {
            platform: data.ad.platform,
            level,
            accountId: data.ad.adAccountId,
            campaignId: data.ad.campaignId,
            adsetId: '',
            adId: '',
            credentialId: data.ad.credentialId,
            isFinish: false,
            identityType: '', //tiktok 平台 广告层级修改广告名称用到
            identityIid: '', //tiktok 平台 广告层级修改广告名称用到
          },
        ],
      };
      this.$showLoading();
      mutateBatchBid(params)
        .then((res) => {
          this.$hideLoading();
          if (res.code == 0) {
            Notify({ type: 'success', message: '修改成功' });
            this.onCancel();
          }
        })
        .catch(() => {});
    },
    setTimeTasks() {
      const { increaseBidAmount, data, level, handleDate, taskType } = this;
      if (!handleDate) {
        Notify({ type: 'warning', message: '请选择要开启的日期！' });
        this.showCalendar = true;
        return;
      }
      if (!taskType) {
        Notify({ type: 'warning', message: '请选择要开启的时间点！' });
        this.showPicker = true;
        return;
      }
      if (!increaseBidAmount) {
        Notify({ type: 'warning', message: '请选择要修改的金额！' });
        this.showAmount = true;
        return;
      }
      const params = {
        platform: data.ad.platform,
        gimpUserId: this.userInfo.data.id,
        adInfos: [
          {
            thirdUserId: '',
            adAccountId: data.ad.adAccountId,
            id: data.ad.campaignId,
          },
        ],
        level,
        // handleDate,
        handleTime: `${handleDate} ${taskType}:00`,
        // taskType: taskType.name,
        increaseBidAmount,
      };
      this.$showLoading();
      settingAdScheduleUpdateBidTasks(params)
        .then((res) => {
          this.$hideLoading();
          if (res.code == 0) {
            Notify({ type: 'success', message: '成功添加定时改出价任务' });
            this.onCancel();
            this.$emit('set-time-success', res.data[0]);
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  min-height: 50vh;
}
</style>
